import PropTypes from 'prop-types';
import React from 'react';
import { AwesomeIcon } from '../AwesomeIcon/AwesomeIcon';

import { SimpleLink } from '../SimpleLink';

const createClassName = (props) => {
  // const arr = Object.values(props);
  return props.join(' ');
};

export const Button = ({
  label,
  className,
  classNameContent,
  children,
  tag: Tag,
  variant,
  color,
  size,
  startIcon,
  endIcon,
  ...props
}) => {
  const combinedClassName = createClassName([variant, color, size]);
  const CustomTag = props.href || props.to ? SimpleLink : Tag;

  return (
    <CustomTag className={`btn ${combinedClassName} ${className}`} {...props}>
      <span className={`content ${classNameContent}`}>
        {startIcon ? <span className="icon-left nom">{startIcon}</span> : null}
        <span>{label || children}</span>
        {endIcon ? <span className="icon-right nom">{endIcon}</span> : null}
      </span>
    </CustomTag>
  );
};

export const ButtonLine = ({
  startIcon,
  endIcon,
  marginLeft = 'ml-10',
  marginRight = 'mr-10',
  className = '',
  ...props
}) => {
  return (
    <Button
      {...props}
      className={`row ${className}`}
      variant="line"
      endIcon={
        endIcon ? (
          <AwesomeIcon
            className={marginLeft}
            icon="long-arrow-right-c"
            prefix="fac"
          />
        ) : null
      }
      startIcon={
        startIcon ? (
          <AwesomeIcon
            className={marginRight}
            icon="long-arrow-left-c"
            prefix="fac"
          />
        ) : null
      }
    ></Button>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'contained',
    'outlined',
    'line',
    'outlined secondary',
    'outlined-secondary',
    'outlined-herbert',
  ]),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'accent',
    'primary transparent',
    'herbert',
  ]),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extra-large']),
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  tag: 'button',
  color: 'primary',
  size: 'medium',
  className: '',
};
