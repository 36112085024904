import React from 'react';

export const BgText = ({ children, className = '', ...props }) => {
  return (
    <div
      data-scroll
      // data-scroll-call="test"
      {...props}
      className={`bg-text ${className}`}
    >
      <span>{children}</span>
    </div>
  );
};
