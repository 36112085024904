import { useEffect, useState } from 'react';
import { checkMobile } from 'utils';

export const useIsMobile = (mediaWidth = 768) => {
  const [isMobile, setIsMobile] = useState(
    checkMobile && window.innerWidth <= mediaWidth,
  );
  useEffect(() => {
    // if (!checkMobile()) return;
    if (window.innerWidth <= mediaWidth) setIsMobile(true);
    const handleResize = () => {
      // if (!checkMobile()) return;
      if (window.innerWidth <= mediaWidth) setIsMobile(true);
      else setIsMobile(false);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [mediaWidth]);
  return isMobile;
};
