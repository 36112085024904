import React from 'react';
import { Helmet } from 'react-helmet';
import { usePage } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

const TeilnahmebedingungenInsta = () => {
  usePage({});
  const [t] = useTranslation(['teilnahmebedingungen-ig', 'translations']);

  return (
    <>
      <Helmet>
        <html lang="de" />
        <title>
          {t(
            'Teilnahmebedingungen Instagram Pinnwand-Gewinnspiele und Gewinnverlosung von Malbuner',
          )}
        </title>
        <meta charset="utf-8" />

        <meta name="description" content="" />
      </Helmet>
      <section data-scroll-section className="pt-25 pb-10">
        <div className="container-middle-big">
          <h3>
            {t(
              'Teilnahmebedingungen Instagram Pinnwand-Gewinnspiele und Gewinnverlosung von Malbuner',
            )}
          </h3>

          <h4>{t('§ 1 Allgemeines')}</h4>
          <p>
            <Trans t={t}>
              Eine Teilnahme an den Instagram Pinnwand-Gewinnspielen
              (nachfolgend „Gewinnspiel“ genannt) der Instagram-Fanseite:
              “Malbuner“ (nachfolgend „die Veranstalterin“ genannt) ist nur
              unter Einbeziehung dieser Teilnahmebedingungen möglich. Das
              Gewinnspiel wird nicht von Instagram unterstützt und steht in
              keiner Verbindung zu Instagram und wird in keiner Weise von
              Instagram gesponsert, unterstützt oder organisiert. Der Empfänger
              der vom Teilnehmer bereitgestellten Informationen ist nicht
              Instagram, sondern HERBERT OSPELT ANSTALT, Schaanerstrasse 79,
              FL-9487 Bendern. Die bereitgestellten Informationen werden einzig
              für das jeweilige Gewinnspiel verwendet. Mit der Teilnahme
              erkennen die Teilnehmer diese Teilnahmebedingungen ausdrücklich an
              und erteilen ihre Zustimmung zur Datenschutzerklärung gemäß § 5
              dieser Teilnahmebedingungen. Der Veranstalter behält sich vor, das
              Gewinnspiel zu jedem Zeitpunkt ohne Vorankündigung und ohne Angabe
              von Gründen abzubrechen oder zu beenden.
            </Trans>
          </p>

          <h4>{t('§ 2 Teilnahmevoraussetzungen')}</h4>
          <p>
            <Trans t={t}>
              Teilnahmeberechtigt sind alle natürlichen und geschäftsfähigen
              Personen ab dem vollendeten 18. Lebensjahr mit Wohnsitz in der
              Schweiz, die über ein echtes Personenprofil auf Instagram
              verfügen. Der Gewinn ist nicht übertragbar. Keine Barablöse. Die
              Gewinner werden schriftlich verständigt. Der Rechtsweg
              ausgeschlossen. Mitarbeiter der HERBERT OSPELT ANSTALT,
              Schaanerstrasse 79, FL-9487 Bendern und deren Angehörige sind von
              der Teilnahme ausgeschlossen. Pro Person ist nur eine Teilnahme
              pro Gewinnspiel möglich. Der/Die Teilnehmer/in erklärt sich mit
              der Teilnahme damit einverstanden, dass im Gewinnfalle sein/ihr
              Name und/oder Bild im Internet veröffentlicht wird. Die Teilnahme
              ist nur innerhalb der in der Gewinnspielbeschreibung genannten
              Frist möglich. Der Rechtsweg ist ausgeschlossen. Kommentare, die
              gegen die Instagram-Richtlinien, Schweizer Recht, unsere
              Netiquette und/oder das Copyright verstoßen, werden nach
              Kenntnisnahme ohne Ankündigung entfernt. Der Teilnehmer ist damit
              von der Verlosung ausgeschlossen. Die Veranstalterin behält sich
              das Recht vor, Teilnehmer vom Gewinnspiel auszuschließen, wenn
              diese das Gewinnspiel manipulieren oder gegen diese
              Teilnahmebedingungen verstoßen.
            </Trans>
          </p>

          <h4>{t('§ 3 Teilnahmezeitraum und Preise')}</h4>
          <p>
            <Trans t={t}>
              Der Start und das Ende der Anmeldung zu Gewinnspielen wird im
              Rahmen jedes Gewinnspiels individuell festgelegt und im jeweiligen
              Gewinnspiel-Post angegeben. Die Gewinne werden im Rahmen jedes
              Gewinnspiels individuell festgelegt und im jeweiligen
              Gewinnspiel-Post angegeben.
            </Trans>
          </p>

          <h4>{t('§ 4 Haftung')}</h4>
          <p>
            <Trans t={t}>
              Schadenersatzansprüche gegenüber der Veranstalterin, die im
              Zusammenhang mit dem Gewinnspiel stehen, sind - innerhalb des
              gesetzlich zulässigen - unabhängig vom Rechtsgrund ausgeschlossen,
              es sei denn, die Veranstalterin hätte vorsätzlich oder grob
              fahrlässig gesetzliche Pflichten verletzt. Ferner haftet die
              Veranstalterin nicht für Schäden aus der Beeinträchtigung der
              Verfügbarkeit der Gewinnspiel Internetseite bei nicht
              beeinflussbaren technischen Störungen und Ereignissen höherer
              Gewalt, sowie Angriffen Dritter gegen die Gewinnspiel-
              Internetseite. Die Veranstalterin wird jedoch alles unternehmen,
              um die Zuverlässigkeit und Funktionsfähigkeit der Gewinnspiel-
              Internetseite sicherzustellen. Weiterhin übernimmt die
              Veranstalterin keine Garantie dafür, dass die
              Gewinnspiel-Internetseite auf dem jeweiligen Teilnehmerrechner
              ordnungsgemäß funktionsfähig ist.
            </Trans>
          </p>

          <h4>{t('§ 5 Datenschutz')}</h4>
          <p>
            <Trans t={t}>
              Die Veranstalterin gewährt im Rahmen des Gewinnspiels den
              größtmöglichen datenschutzrechtlichen Standard und beachtet alle
              diesbezüglich einschlägigen gesetzlichen Bestimmungen. Die
              Veranstalterin wird keine personenbezogenen Daten an Dritte
              weiterleiten oder Adressdaten verkaufen. Die Veranstalterin
              speichert die personenbezogenen Daten der jeweiligen Teilnehmer/
              in ausschließlich zum Zwecke des Gewinnspiels. Der/Die
              Teilnehmer/in erklärt hiermit ausdrücklich sein/ihr Einverständnis
              mit der Speicherung und Verwendung der mitgeteilten
              personenbezogenen Daten zu dem oben genannten Zweck.
            </Trans>
          </p>

          <h4>{t('§ 6 Salvatorische Klausel')}</h4>
          <p>
            <Trans t={t}>
              Sollten einzelne Bestimmungen dieser Teilnahmebedingungen
              unwirksam sein oder eine Regelungslücke bestehen, berührt dies die
              Wirksamkeit der übrigen Bestimmungen nicht. An die Stelle der
              unwirksamen oder fehlenden Bestimmungen tritt eine Bestimmung,
              welche dem Vertragszweck und den gesetzlichen Bestimmungen am
              nächsten kommt.
            </Trans>
          </p>

          <h4>{t('§ 7 Gerichtsstand / anwendbares Recht')}</h4>
          <p>
            <Trans t={t}>
              Bei Streitigkeiten gilt ausschließlich Schweizer Recht. Als
              Gerichtsstand wird, soweit gesetzlich zulässig, der Sitz der
              Veranstalterin vereinbart. Soweit der/die Teilnehmer/in keinen
              allgemeinen Gerichtsstand in der Schweiz hat, oder nach der
              Teilnahme den Wohnsitz ins Ausland verlegt, wird der Sitz der
              Veranstalterin ebenso als Gerichtsstand vereinbart.
            </Trans>
          </p>

          <h4>{t('§ 8 Durchführung und Abwicklung')}</h4>
          <p>
            <Trans t={t}>
              Die Verlosung des Gewinns erfolgt nach Ablauf des
              Durchführungszeitraumes unter Ausschluss der Öffentlichkeit und
              Gewährleistungen des Zufallsprinzips. Die technische Durchführung
              obliegt der Veranstalterin. Die Gewinner werden per
              Privatnachricht oder (falls eine Kontaktaufnahme über eine
              Privatnachricht nicht möglich ist) über die
              Instagram-Kommentarfunktion unter dem Gewinnspiel-Posting
              informiert. Mit dieser Art der Benachrichtigung erklärt sich der
              Teilnehmer/Gewinner ausdrücklich einverstanden. Gewinner müssen
              innerhalb von 5 Werktagen (ab Gewinnbenachrichtigung) ihre
              Kontakt- und Adressdaten vollständig und wahrheitsgemäß per
              Privatnachricht an die „Malbuner“ Instagram-Seite senden. Der
              Gewinn wird von der Veranstalterin per Post zugesendet. Sollten
              Gewinner die Annahme des Gewinnes in dieser Zeit nicht bestätigen,
              verfällt ihr Anspruch auf den Gewinn ersatzlos. Eine Verpflichtung
              zur Annahme des Gewinnes besteht nicht. Eine Barauszahlung der
              Gewinne ist in keinem Falle möglich.
            </Trans>
          </p>

          <h4>{t('§ 9 Schlussbestimmungen')}</h4>
          <p>
            <Trans t={t}>
              Der Rechtsweg ist ausgeschlossen. Über das Gewinnspiel wird kein
              Schriftverkehr geführt. Es ist ausschließlich Schweizer Recht
              anwendbar.
            </Trans>
          </p>

          <h4>{t('§ 10 Kontakt')}</h4>
          <p>
            <Trans t={t}>
              Bei Fragen in Bezug auf die Durchführung der Gewinnspiele von
              Malbuner und der Verarbeitung und Nutzung von persönlichen Daten
              wenden Sie sich bitte an: info@malbuner.ch
            </Trans>
          </p>
        </div>
      </section>
    </>
  );
};

export default TeilnahmebedingungenInsta;
