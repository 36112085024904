import React from 'react';
import PropTypes from 'prop-types';

export const InViewFade = ({
  children,
  'data-scroll-offset': offset,
  'data-scroll-speed': speed,
  tag: Tag = 'div',
  ...props
}) => {
  if (speed)
    return (
      <div
        data-scroll
        data-scroll-speed={speed}
        data-scroll-offset={offset}
        style={{ perspective: '1000px' }}
      >
        <FadeIn tag={Tag} {...props}>
          {children}
        </FadeIn>
      </div>
    );

  return (
    <FadeIn
      tag={Tag}
      data-scroll
      data-scroll-offset={offset || '15%, 0%'}
      {...props}
    >
      {children}
    </FadeIn>
  );
};

export const FadeIn = ({
  children,
  className = '',
  delay = 0,
  style,
  fade = 'top',
  tag: Tag = 'div',
  ...props
}) => {
  return (
    <Tag
      className={`fade-inview noPointer ${fade} ${className}`}
      style={{
        ...style,
        transitionDelay: delay + 'ms',
      }}
      {...props}
    >
      {children}
    </Tag>
  );
};
// export const withFadeIn = (Component, opts) => ({ delay, ...props }) => {
//   return (
//     <InViewFade {...opts}>
//       <Component {...props} />
//     </InViewFade>
//   );
// };

InViewFade.defaultProps = {
  className: '',
  delay: 0,
  fade: 'top',
};

FadeIn.propTypes = {
  delay: PropTypes.number,
  fade: PropTypes.oneOf([
    'top',
    'left',
    'right',
    'bottom',
    '',
    'opacity',
    'bend-top',
    'bend-bottom',
    'bend-left',
    'bend-right',
    'bend-top-reverse',
    'bend-bottom-reverse',
    'bend-left-reverse',
    'bend-right-reverse',
    'scale',
  ]),
};
