import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const query = new URLSearchParams(window.location.search);
let lng =
  query.get('lng') ||
  window.localStorage.getItem('lng') ||
  window.navigator.language.slice(0, 2);
const supportedLngs = ['de', 'fr' /*'it'*/];

if (lng) {
  if (!supportedLngs.includes(lng)) {
    lng = null;
  } else {
    window.localStorage.setItem('lng', lng);
  }
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: lng || 'de',
    backend: {
      // translation file path
      loadPath: `/lng/i18n/{{ns}}/{{lng}}.json`,
    },
    supportedLngs,
    fallbackLng: 'de',
    debug: false,
    // debug: process.env.NODE_ENV === 'development',
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations', 'homepage'],
    defaultNS: 'translations',
    keySeparator: false,
    nsSeparator: '#',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    transSupportBasicHtmlNodes: true,
    react: {
      wait: true,
    },
  });
