import React from 'react';
import { Media, InViewFade } from 'ui';

export const Deco = ({ container, zIndex, ...props }) => {
  if (container) {
    return (
      <div style={{ zIndex: zIndex }} className={'fw fh absolute'}>
        <DecoContent {...props} />
      </div>
    );
  }

  return <DecoContent zIndex={zIndex} {...props} />;
};

const checkSize = (className) => {
  switch (className) {
    case 'rm-md':
      return window.innerWidth <= 768;
    default:
      return false;
  }
};

const DecoContent = ({
  position = 'absolute',
  className = '',
  style,
  src,
  bgColor = '',
  width,
  height,
  top,
  left,
  bottom,
  right,
  zIndex,
  rotate,
  translate,
  directChild,
  children,
  noFadeIn,
  ...props
}) => {
  // return null;
  if (checkSize(className)) return null;
  return (
    <div
      className={`deco ${position} ${bgColor} ${className} ${
        noFadeIn ? 'no-fade' : ''
      }`}
      style={{
        width: width,
        height: height,
        top: top,
        left: left,
        right: right,
        bottom: bottom,
        zIndex: zIndex,
        ...style,
      }}
    >
      {directChild}
      <InViewPicker
        noFadeIn={noFadeIn}
        {...props}
        className={position === 'relative' ? 'fw fh' : ''}
      >
        <div>
          {!src ? null : (
            <Media
              className="fw fh"
              style={{ transform: `rotate(${rotate})` }}
              src={src}
              loading="lazy"
            />
          )}
          {children}
        </div>
      </InViewPicker>
    </div>
  );
};

const InViewPicker = ({ children, noFadeIn, ...props }) => {
  if (noFadeIn) return <div {...props}>{children}</div>;
  return <InViewFade {...props}>{children}</InViewFade>;
};
