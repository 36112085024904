export const Tabs = ({
  title,
  activeClass = 'active',
  active,
  num,
  className,
  ...props
}) => {
  return (
    <div {...props} className={`tabs ${className} ${active && activeClass}`}>
      <div className="row justify-space-between flex-align-center">
        <p className="nom">{title}</p>
        {num && (
          <span className="num flexbox justify-center flex-align-center">
            <div>{num}</div>
          </span>
        )}
      </div>
    </div>
  );
};
