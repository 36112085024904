import { useEffect, useRef, useState } from 'react';

export const useLoaded = (loaded, transitionDelay) => {
  const timeout = useRef();
  const [state, setState] = useState(false);

  useEffect(() => {
    clearTimeout(timeout.current);
    if (!loaded) return setState(loaded);
    timeout.current = setTimeout(() => setState(loaded), transitionDelay + 200);
  }, [loaded, transitionDelay]);

  return state;
};
