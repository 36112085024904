import React from 'react';
import ReactDOM from 'react-dom';

import { Spring, animated } from 'react-spring';

import { AwesomeIcon } from 'ui';

export const ModalBox = ({ open, children, onClose = () => {} }) => {
  const handleClose = () => {
    onClose();
  };

  // const springRef = new SpringRef();

  // springRef.start({
  //   to: { opacity: 1 },
  // });

  return ReactDOM.createPortal(
    <div className={`modalbox fw ${open ? 'open' : ''}`}>
      <div className="fh">
        <div
          className="fw fh absolute"
          style={{ top: 0, left: 0 }}
          onClick={() => handleClose()}
        />
        <AwesomeIcon
          size="3x"
          className="close-icon black"
          onClick={() => handleClose()}
          prefix="fac"
          icon="times"
          style={{ zIndex: 200 }}
        />

        {open && (
          <>
            <div className="lds-dual-ring"></div>
            <Spring delay={850} from={{ opacity: 0 }} to={{ opacity: 1 }}>
              {(styles) => (
                <animated.div style={{ height: '100%', ...styles }}>
                  {children}
                </animated.div>
              )}
            </Spring>
          </>
        )}
      </div>
    </div>,
    document.querySelector('#root'),
  );
};
