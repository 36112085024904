import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { Media } from '../../atoms/Media';

import { useLocomotive } from 'context';
import { useResize } from 'hooks';

const MoleculePlaceholder = ({ title, subtitle }) => {
  return (
    <div>
      <h1 className="title">{title}</h1>
      {subtitle ? <p className="large">{subtitle}</p> : null}
    </div>
  );
};

MoleculePlaceholder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export const Header = ({
  title,
  subtitle,
  description,
  src,
  srcSet,
  mobileSrc,
  mobileSrcSet,
  tag,
  className,
  classNameContainer,
  classNameContent,
  children,
  style,
  center,
  dataScrollSection = false,
  mediaStyle = {},
  ...props
}) => {
  //   let test = document.getElementById("test")
  // console.log(test);
  const { scroll } = useLocomotive();
  const ref = useRef();

  useEffect(() => {
    const handleScroll = (obj) => {
      if (!ref.current) return;
      if (obj.currentElements?.test?.progress) {
        ref.current.style.transform = `scale(${
          1 + (obj.currentElements.test.progress - 0.5) / 4
        })`;
      }
      // console.log(obj.currentElements.test.progress);
    };
    // scroll.on('scroll', handleScroll);

    return () => {
      scroll?.off('scroll', handleScroll);
    };
  }, [scroll]);

  const actualSrc = useMemo(
    () =>
      tag === 'video' && props.srcSet
        ? props.srcSet.split(',').reduce((acc, cur) => {
            if (acc) return acc;
            const [src, width] = cur.trim().split(' ');
            if (window.innerWidth < width) return src;
            return acc;
          }, null)
        : src,
    [src, props.srcSet, tag],
  );

  const { w } = useResize();

  return (
    <>
      <div
        ref={ref}
        data-scroll
        data-scroll-id="test"
        data-scroll-class="header-in-view"
        className={`header overflow-hidden ${className}`}
        style={{ ...style }}
      >
        <Media
          {...props}
          data-scroll
          data-scroll-speed={-1.5}
          style={{ height: '110%', ...mediaStyle }}
          src={w <= 450 ? mobileSrc || src : actualSrc || src}
          srcSet={w <= 450 ? null : srcSet}
          tag={tag}
        />
        <div
          data-scroll
          data-scroll-speed={1}
          data-scroll-position="top"
          data-scroll-class="header-in-view"
          className={`content ${classNameContainer} ${classNameContent} ${
            center ? '' : 'nom'
          }`}
          style={{ zIndex: 2 }}
        >
          {children ? (
            children
          ) : (
            <MoleculePlaceholder title={title} subtitle={subtitle} />
          )}
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  src: PropTypes.string,
  tag: PropTypes.string,
  className: PropTypes.string,
  classNameContent: PropTypes.string,
  children: PropTypes.node,
};

Header.defaultProps = {
  className: '',
  classNameContent: '',
  classNameContainer: 'container',
};
