import { config, pixelConfig } from 'config';
import React, {
  useState,
  createContext,
  useEffect,
  useContext,
  useRef,
} from 'react';

import { getData, saveData } from 'utils';
import { CookiePopup } from 'components/CookiePopup';
import { usePageView } from 'components/CookiePopup/ReactRouterGa';
// import ReactPixel from 'react-facebook-pixel';

const initialState = {
  essential: true,
  statistics: false,
  media: false,
  noticed: false,
};
const COOKIE_NOTICE_LS = 'cookie-notice-3';

const CookieContext = createContext();
export const useCookiePopup = () => useContext(CookieContext);

export const CookieContextProvider = ({ children, includePopup = true }) => {
  const [state, setState] = useState(getData(COOKIE_NOTICE_LS) || initialState);
  const gaRef = useRef();
  const pixelRef = useRef();

  // handle Google Analytics
  useEffect(() => {
    if (state.noticed) saveData(COOKIE_NOTICE_LS, state);
    // eslint-disable-next-line
  }, [state.noticed]);

  useEffect(() => {
    if (state.noticed && state.statistics && !gaRef.current) {
      import('utils/ReactGA').then(async (ReactGA) => {
        await ReactGA.initialize(config.googleAnalyticsId);
        gaRef.current = ReactGA;
        ReactGA.pageview(window.location.pathname);
      });
    }
    if (!pixelRef.current && state.statistics) {
      import('react-facebook-pixel').then(async ({ default: ReactPixel }) => {
        ReactPixel.init(
          pixelConfig.facebookPixelId,
          pixelConfig.advancedMatching,
          pixelConfig.options,
        );
        ReactPixel.init(
          pixelConfig.facebookPixelIdOspelt,
          pixelConfig.advancedMatching,
          pixelConfig.options,
        );
        pixelRef.current = ReactPixel;
        ReactPixel.pageView(window.location.pathname);
      });
    }
  }, [state.noticed, state.statistics]);

  usePageView((pathname) => {
    if (state.statistics) {
      gaRef?.current?.pageview(pathname);
      pixelRef?.current?.pageView(pathname);
    }
  });

  return (
    <>
      <CookieContext.Provider
        value={{
          ...state,
          essential: true,
          setCookiePreferences: (changes) => setState({ ...state, ...changes }),
        }}
      >
        {includePopup ? <CookiePopup /> : null}
        {children}
      </CookieContext.Provider>
    </>
  );
};

