import React from 'react';

export const BgDeco = ({
  src,
  style,
  className,
  position = 'center',
  size = '50%',
  pb = 0,
  pt = 0,
  pr = 0,
  pl = 0,
  ...props
}) => {
  return (
    <div
      {...props}
      className={`bg-deco ${className}`}
      style={{
        backgroundImage: `url(${src})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: position,
        backgroundSize: size,
        width: `${100 - pr - pl}%`,
        height: `${100 - pt - pb}%`,
        top: `${pt}%`,
        left: `${pl}%`,
        ...style,
      }}
    />
  );
};
