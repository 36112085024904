import React from 'react';
import PropTypes from 'prop-types';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faSearch,
  faSpinner,
  faAngleDoubleRight,
  faArrowsAlt,
  faUndo,
  faFilePdf,
  faCaretDown,
  faCaretUp,
  faVolumeUp,
  faVolumeMute,
} from '@fortawesome/free-solid-svg-icons';

import {
  faInstagram,
  faTwitter,
  faFacebook,
  faYoutube,
  faSpotify,
} from '@fortawesome/free-brands-svg-icons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import {
  faLongArrowLeft,
  faLongArrowLeftC,
  faLongArrowRight,
  faLongArrowRightC,
  faHistoryArrow,
  faArrowRight,
  faArrowLeft,
  faArrowDown,
  faArrowUp,
  faPlay,
  faChevronRight,
  faChevronLeft,
  faOven,
  faScrollDown,
  faTimes,
  faPlus,
  faMinus,
  faNachhaltigeProdukte,
  faKlima,
  faFoodWaste,
  faArtenvielfalt,
  faRecycle,
  faPlastik,
  faPause,
  faFleisch,
  faMilch,
  faGluten,
  faEier,
  faSenf,
  faSellerie,
  faPistazien,
  faSchwefeldioxid,
  faSoja,
  faVegan,
  faSponsorFormIcon,
} from './custom-icons';

library.add(
  faSponsorFormIcon,
  faEnvelope,
  faInstagram,
  faTwitter,
  faFacebook,
  faSpotify,
  faPhone,
  faLongArrowLeft,
  faLongArrowLeftC,
  faLongArrowRight,
  faLongArrowRightC,
  faHistoryArrow,
  faArrowRight,
  faArrowLeft,
  faArrowDown,
  faArrowUp,
  faPlay,
  faChevronRight,
  faChevronLeft,
  faOven,
  faScrollDown,
  faTimes,
  faClock,
  faMapMarkerAlt,
  faSearch,
  faPlus,
  faMinus,
  faNachhaltigeProdukte,
  faKlima,
  faFoodWaste,
  faArtenvielfalt,
  faRecycle,
  faPlastik,
  faPause,
  faSpinner,
  faAngleDoubleRight,
  faFleisch,
  faMilch,
  faGluten,
  faEier,
  faSenf,
  faSellerie,
  faPistazien,
  faSchwefeldioxid,
  faSoja,
  faArrowsAlt,
  faUndo,
  faFilePdf,
  faCaretDown,
  faCaretUp,
  faVolumeUp,
  faVolumeMute,
  faYoutube,
  faVegan,
);

export const AwesomeIcon = ({ ...props }) => {
  return <FontAwesomeIcon {...props} icon={[props.prefix, props.icon]} />;
};

AwesomeIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  prefix: PropTypes.string,
};

AwesomeIcon.defaultProps = {
  prefix: 'fas',
};
