import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Section, InViewFade } from 'ui';
const MapBox = React.lazy(() => import('components/Mapbox'));

const locations = [
  {
    lat: 47.1990193,
    lng: 9.5019399,
    title: 'Bendern',
    address: ['Ludersdorf 202', '8200 Gleisdorf'],
  },
  {
    lat: 47.0468461,
    lng: 9.4471747,
    title: 'Sargans',
    address: [' 27', '8250 Vorau'],
  },
  {
    lat: 47.1002433,
    lng: 9.6103855,
    title: 'Stuba',
    address: ['IZ NÖ-Süd Strasse 3', '2351 Wiener Neudorf'],
  },
];

export const MapSection = () => {
  const [t] = useTranslation(['kontakt', 'laden']);
  const [state, setState] = useState({
    lat: 47.1100234,
    lng: 9.4691995,
    zoom: 10,
  });

  return (
    <Section className="nop">
      <InViewFade>
        <div style={{ height: '500px' }}>
          <MapBox {...state} locations={locations} />
        </div>
      </InViewFade>

      <InViewFade>
        <div className="row map-info">
          <div
            className="bg-secondary-light column flex-align-md-end pointer p-4 pr-0 ss"
            onClick={() =>
              setState({ lat: 47.1100234, lng: 9.4691995, zoom: 10 })
            }
          >
            <div className="br pr-4 pt-2 pb-2">
              <p className="fw-bold mb-x1">{t('Übersicht')}</p>
              <p className="small">
                {t('Malbuner')}
                <br />
                {t('Standorte')}
                <br />
              </p>
            </div>
          </div>
          <div
            className="bg-secondary-light column flex-align-md-end pointer p-4 pr-0 ss"
            onClick={() =>
              setState({ lat: 47.1990193, lng: 9.5019399, zoom: 16 })
            }
          >
            <div className="br pr-4 pt-2 pb-2">
              <p className="fw-bold mb-x1">{t("Fabrikladen Bendern", {ns: "laden"})}</p>
              <p className="small">
                Schaanerstrasse 79
                <br /> FL-9487 Bendern <br />
              </p>
            </div>
          </div>
          <div
            className="bg-secondary-light pointer p-4 pr-0 ss"
            onClick={() =>
              setState({ lat: 47.0468461, lng: 9.4471747, zoom: 16 })
            }
          >
            <div className="br pr-4 pt-2 pb-2">
              <p className="fw-bold mb-x1">{t("Fabrikladen Sargans", {ns: "laden"})}</p>
              <p className="small">
                Tiefrietstrasse 7 <br /> CH-7320 Sargans
              </p>
            </div>
          </div>
          <div
            className="bg-secondary-light pointer p-4 pr-0 ss"
            onClick={() =>
              setState({ lat: 47.1002433, lng: 9.6103855, zoom: 16 })
            }
          >
            <div className="br pr-4 pt-2 pb-2">
              <p className="fw-bold mb-x1">{t("Malbuner Stuba", {ns: "laden"})}</p>
              <p className="small">
                Adam-Kraft-Strasse 16 <br /> FL-9497 Triesenberg
              </p>
            </div>
          </div>
          <div className="bg-secondary-light pr-md-10  p-4 pr-0 ss column justify-center">
            <div className="pr-4 pt-2 pb-2 column justify-center">
              <a href="tel:0041583771000" className="fw-bold mb-x1">
                {t("Telefon")}: +41 58 377 1000
              </a>
              <br />
              <a href="mailto:info@malbuner.ch" className="fw-bold mb-x1">
                {t("Email")}: info@malbuner.ch
              </a>
            </div>
          </div>
        </div>
      </InViewFade>
    </Section>
  );
};
