import React, { createContext, useContext, useState } from 'react';

const NavContext = createContext();

export const useNav = () => useContext(NavContext);

export const NavContextProvider = ({ children }) => {
  const [nav, setNav] = useState('white');

  // use to set extra classes for different nav states
  // e.g. white, hidden
  return (
    <NavContext.Provider value={{ setNav, nav }}>
      {children}
    </NavContext.Provider>
  );
};
