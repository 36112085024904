import { useEffect } from 'react';
import { useLocation } from 'react-router';
import * as ReactGa from 'utils/ReactGA';
import { useCookiePopup } from 'context';

export const ReactRouterGa = ({ children }) => {
  const { pathname } = useLocation();
  const { noticed, statistics } = useCookiePopup();

  useEffect(() => {
    if (!noticed || !statistics) return;
    ReactGa.pageview(pathname);
  }, [pathname, noticed, statistics]);

  return children || null;
};

export const usePageView = (callback = () => {}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    callback(pathname);
  }, [pathname, callback]);

  return null;
};
