import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useLocomotive } from 'context/locomotive-scroll-context';

export const SimpleLink = ({ href, to, children, label, ...props }) => {
  const { scroll } = useLocomotive();

  if (href) {
    return (
      <a href={href} {...props}>
        {label || children}
      </a>
    );
  }

  if (to) {
    return (
      <Link
        onClick={() => {
          if (to.search('#') === -1) return;
          scroll.scrollTo(to);
        }}
        to={to}
        {...props}
      >
        {label || children}
      </Link>
    );
  }
};

SimpleLink.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  label: PropTypes.string,
};
