import React from 'react';
import PropTypes from 'prop-types';
import { DecoStroke } from '../../atoms/DecoStroke';
import { InViewFade } from 'ui/Animation/InViewFade';
import { useTheme } from 'context';

export const Title = ({
  deco,
  subtitle,
  title,
  color,
  colorSubtitle,
  className,
  classNameSubtitle,
  children,
  splitBy,
  subtitleProps = {},
  ...props
}) => {
  const { theme } = useTheme();
  const text = title ? title : typeof children === 'string' ? children : null;

  return (
    <>
      <div
        {...subtitleProps}
        data-scroll
        data-scroll-offset="30%,0%"
        className={`h6 display a-title ${colorSubtitle} ${classNameSubtitle}`}
      >
        <span>{subtitle}</span>
      </div>
      <h2
        data-scroll
        data-scroll-offset="30%,0%"
        className={`a-title ${color} ${className}`}
        {...props}
      >
        {(text ? text.split(splitBy) : React.Children.toArray(children)).map(
          (el, i) => (
            <span style={{ transitionDelay: `${i * 50}ms` }} key={i}>
              {el}
            </span>
          ),
        )}
      </h2>
      {theme === 'theme-onkel-herbert' || deco ? (
        <InViewFade fade="left">
          <DecoStroke className="mt-4 mb-3" />
        </InViewFade>
      ) : null}
    </>
  );
};

Title.propTypes = {
  color: PropTypes.oneOf([
    'accent',
    'accent-dark',
    'white',
    'black',
    'n-green',
    'title-vegan',
    '',
  ]),
  colorSubtitle: PropTypes.oneOf([
    'accent',
    'accent-dark',
    'white',
    'black',
    'n-green',
    '',
  ]),
  subtitle: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};

Title.defaultProps = {
  color: 'accent',
  subtitle: '',
  title: '',
  className: '',
  classNameSubtitle: '',
  splitBy: '|',
};
