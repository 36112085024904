import './polyfill';

import 'scss/vendor/swiper.css';
import './scss/index.min.css';
import Bowser from 'bowser';

import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
// import * as Sentry from '@sentry/react';

import 'config/i18n';

import { BrowserRouter } from 'react-router-dom';
import { isIE, isSafari } from 'utils';

import MainRouter from './router';
import Context from 'context';

const PolyfillLoader = React.lazy(() => import('./PolyfillLoader'));

const browser = Bowser.getParser(window.navigator.userAgent);
const isModernBrowser = browser.satisfies({
  chrome: '>65',
  edge: '>80',
  firefox: '>69',
  opera: '>70',
  safari: '>14',
});

if (isIE()) {
  document.body.classList.add('is-ie');
}

if (isSafari) {
  document.body.classList.add('is-safari');
}

// Sentry.init({
//   dsn: 'https://fc78fea44ac8718d40e2d540df4020d8@o4506638154006528.ingest.sentry.io/4506659691364352',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//     }),
//     new Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const root = createRoot(document.getElementById('root'));

root.render(
  <Suspense fallback={null}>
    {/* if not normaly working browser do the polyfills */}
    {!isModernBrowser ? (
      <PolyfillLoader>
        <BrowserRouter>
          <Context>
            <MainRouter />
          </Context>
        </BrowserRouter>
      </PolyfillLoader>
    ) : (
      <BrowserRouter>
        <Context>
          <MainRouter />
        </Context>
      </BrowserRouter>
    )}
  </Suspense>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
