import { useEffect, useRef } from 'react';
import { useTheme, useNav } from 'context';
import { transitionDelay } from 'router';

export const usePage = ({ nav = '', theme = '' }) => {
  const timeout = useRef();
  const timeout2 = useRef();
  const { setTheme } = useTheme();
  const { setNav } = useNav();

  useEffect(() => {
    window.onload = () => {
      setTheme(theme);
      setNav(nav);
    };

    clearTimeout(timeout.current);
    clearTimeout(timeout2.current);
    timeout2.current = setTimeout(() => {
      setTheme(theme);
    }, transitionDelay * 0.4);
    timeout.current = setTimeout(() => {
      setNav(nav);
    }, transitionDelay);
  }, [setTheme, setNav, nav, theme]);

  // return init;
};

// usePage({ theme: 'theme-malbuner', nav: 'white' });
