import { useState, useMemo } from 'react';
import useResizeObserver from 'use-resize-observer';
import debounce from 'lodash/debounce';

export const useDebouncedResizeObserver = ({ ref, wait = 300 }) => {
  const [size, setSize] = useState({});
  // ignore problem pls? didnt work :()
  const onResize = useMemo(() => debounce(setSize, wait, { leading: true }), [
    wait,
  ]);

  useResizeObserver({ onResize, ref });

  return { ...size };
};
