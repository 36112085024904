export * from './cockpit';
export * from './staticData';
export * from './links';

export const config = {
  googleAnalyticsId: 'G-850XNYE18C',
};

export const pixelConfig = {
  facebookPixelId: '641706319790978',
  facebookPixelIdOspelt: '1100540021207002',
  advancedMatching: {},
  options: { autoConfig: true, debug: false },
};

export const GEWINNSPIEL_API_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:5031' : '';
