import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { AwesomeIcon } from 'ui';
// import { useLocomotive } from 'context';
import { isIE } from 'utils';
const Slider = React.lazy(() => import('ui/organisms/Slider'));

export const Gallery = ({ children, noGrid }) => {
  const [open, setOpen] = useState({ isOpen: false, index: 0 });
  const isitIE = isIE();

  const handleOpen = (index) => {
    setOpen({ isOpen: true, index });
  };

  const handleClose = () => {
    setOpen({ isOpen: false, index: open.index });
  };

  const renderGrid = () => {
    const length = children.length;
    const arrays = Math.ceil(length / 13);

    const newArray = [];

    for (var i = 0; i < arrays; i++) {
      if (i === arrays) return;
      newArray.push(children.slice(i * 13, (i + 1) * 13));
    }

    return newArray.map((arr, i) => {
      return (
        <div key={i} className={isitIE ? `isIE-gallery-grid` : `gallery-grid`}>
          {arr.map((child, x) => {
            return (
              <div
                key={x}
                onClick={() => handleOpen(x + 1 + i * 13)}
                className={
                  isitIE ? `isIE-gallery-grid-item-${x}` : `gallery-grid-item`
                }
              >
                {/* <InViewFade
                  fade="opacity"
                  style={{ height: '100%', width: '100%' }}
                > */}
                {child}
                {/* </InViewFade> */}
              </div>
            );
          })}
        </div>
      );
    });
  };

  return (
    <div id="gallery-container">
      <Lightbox open={open.isOpen} index={open.index} onClose={handleClose}>
        {children}
      </Lightbox>

      {noGrid ? (
        <div className="row" style={{ padding: '2px' }}>
          {children.map((child, i) => {
            return (
              <div
                onClick={() => handleOpen(i + 1)}
                key={i}
                style={{ padding: '1px' }}
                className="col-md-4"
              >
                <div className="overflow-hidden gallery-hover">
                  {/* <InViewFade
                    fade="opacity"
                    style={{
                      height: '100%',
                      width: '100%',
                    }}
                  > */}
                  {child}
                  {/* </InViewFade> */}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        renderGrid()
      )}
    </div>
  );
};

const Lightbox = ({ open, index, onClose, children }) => {
  const [swiper, setSwiper] = useState(null);
  const [t, i18n] = useTranslation(['translations']);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (swiper) swiper.slideTo(index - 1, 0);
  }, [swiper, index]);
  console.log(i18n.language);

  return ReactDOM.createPortal(
    <div className={`lightbox fw ${open ? 'open' : ''}`}>
      <div className="fh pt-10 pb-10">
        <div
          className="fw fh absolute"
          style={{ top: 0, left: 0 }}
          onClick={() => handleClose()}
        />
        <AwesomeIcon
          size="3x"
          className="close-icon"
          onClick={() => handleClose()}
          prefix="fac"
          icon="times"
        />
        <div
          className="swiper-prev-button absolute"
          onClick={() => swiper.slidePrev()}
        >
          <AwesomeIcon size="3x" prefix="fac" icon="chevron-left" />
        </div>
        <div
          className="swiper-next-button absolute"
          onClick={() => swiper.slideNext()}
        >
          <AwesomeIcon size="3x" prefix="fac" icon="chevron-right" />
        </div>
        <Slider
          preloadImages={false}
          lazy
          onSwiper={setSwiper}
          activeIndex={index}
          className="fh"
        >
          {children.map((child, i) => (
            <div key={i} className="fw fh row justify-center flex-align-center">
              {(child.props.lightboxTag || child.props.tag) === 'video' ? (
                <SwiperVideo
                  open={open}
                  controls
                  alt=""
                  className="swiper-lazy"
                  src={
                    child.props[`data-src_${i18n.language}`] ||
                    child.props['data-video'] ||
                    child.props['data-src'] ||
                    child.props.src
                  }
                />
              ) : (
                <>
                  <img
                    alt=""
                    className="swiper-lazy"
                    data-src={child.props['data-src'] || child.props.src}
                  />

                  <div className="fw">
                    <a
                      onClick={(e) => e.stopPropagation()}
                      className="btn outlined secondary small"
                      target="_blank"
                      rel="noreferrer"
                      href={child.props.link ? child.props.link : ''}
                      style={{
                        display: child.props.link ? 'block' : 'none',
                        position: 'absolute',
                        bottom: '8px',
                        right: '50%',
                        transform: 'translateX(50%)',
                        zIndex: 2,
                      }}
                    >
                      <span className="content">{t('zum Video')}</span>
                    </a>
                  </div>
                </>
              )}
              <div className="swiper-lazy-preloader"></div>
            </div>
          ))}
        </Slider>
      </div>
    </div>,
    document.querySelector('#root'),
  );
};

const SwiperVideo = ({ open, ...props }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!open) {
      ref.current.pause();
    }
  }, [open]);

  return <video ref={ref} {...props}></video>;
};
