import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AwesomeIcon } from 'ui';

export const Accordion = ({ title, text }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef();

  const [t] = useTranslation(['faq', 'translations']);

  const handleClick = (e) => {
    setOpen(!open);
  };

  useEffect(() => {
    const el = ref.current;
    const elHeight = el.scrollHeight;

    if (open) {
      el.style.height = elHeight + 'px';
    } else {
      el.style.height = '0px';
    }
  }, [open]);

  return (
    <div className={`accordion mb-3 ${open && 'open'}`}>
      <div onClick={handleClick}>
        <h4 className="title">
          <span className="icon-container">
            <AwesomeIcon
              prefix="fac"
              className="icon"
              icon={open ? 'minus' : 'plus'}
            />
          </span>
          <span className="tt">{title}</span>
        </h4>
      </div>
      <div ref={ref} className="content overflow-hidden" style={{ height: 0 }}>
        <div>
          <p dangerouslySetInnerHTML={{ __html: t(text) }}></p>
        </div>
      </div>
    </div>
  );
};
