import React from 'react';
import PropTypes from 'prop-types';

export const Checkbox = ({ checked, type, name, ...props }) => {
  return (
    <label className={`checkbox ${checked ? 'checked' : ''}`}>
      <input type={type} name={name} hidden checked={checked} {...props} />
      {/* <div className="checkbox-slider"></div> */}
    </label>
  );
};

Checkbox.defaultProps = {
  checked: false,
  type: 'checkbox',
  name: '',
  onChange: () => {},
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
};
