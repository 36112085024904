const warn = (s) => {
  // console.warn('[react-ga]', s);
};

export const initialize = (id) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    script.setAttribute('async', true);
    script.onload = () => {
      // console.log('google analytics loaded');
      // dont really know if this is needed but i think it is
      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gId = id;
      window.gtag('config', window.gId);

      window.gtag('config', 'DC-13792747');
      resolve();
    };

    script.onerror = () => reject('couldnt load script');

    document.body.appendChild(script);
  });

export const pageview = (path) => {
  if (!path) return warn('path is required in .pageview()');
  if (!window.gId || !window.gtag) {
    warn(
      'ReactGA.initialize must be called first or GoogleAnalytics should be loaded manually',
    );
    return;
  }

  window.gtag('config', window.gId, { page_path: path });

  window.gtag('event', 'conversion', {
    allow_custom_scripts: true,
    send_to: 'DC-13792747/visit/pageview+standard',
    page_path: path,
  });
};
