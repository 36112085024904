const GenerateApiUrls = function ({ domain, token, cockpitFolder }) {
  const DOMAIN = domain;

  const ROOT_URL = `/${cockpitFolder}`;
  const API_TOKEN = `?token=${token}`;

  // if domain is needed elsewhere
  this.DOMAIN = DOMAIN;
  this.ASSET_URL = DOMAIN + ROOT_URL + '/storage/uploads';
  this.collection = (name) =>
    `${DOMAIN}${ROOT_URL}/api/collections/get/${name}${API_TOKEN}`;
  this.singleton = (name) =>
    `${DOMAIN}${ROOT_URL}/api/singletons/get/${name}${API_TOKEN}`;
  this.image = (path, { w, h, m = 'thumbnail', q = 85 }) =>
    `${DOMAIN}${ROOT_URL}/api/cockpit/image${API_TOKEN}&src=${DOMAIN}${path}&w=${w}&h=${h}&o=true&q=${q}&m=${m}`;
};

export const Cockpit = new GenerateApiUrls({
  cockpitFolder: 'cockpit-cms',
  token: 'ec618c0401b78a29d2daa3039140b5',
  domain:
    process.env.NODE_ENV === 'development'
      ? 'https://malbuner.ch'
      : 'https://malbuner.ch',
});
