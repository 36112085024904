import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Switch, Route, useLocation, matchPath } from 'react-router-dom';

// SETUP ROUTES
import { LocomotiveScrollProvider } from 'context';
import { useTransition, a } from 'react-spring';
import { useLoaded } from 'hooks/useLoaded';

// Lazy import page components
import { routes } from './routes';
import { IS_IE } from 'utils';
// import { GewinnspielBanner } from 'components/GewinnspielBanner';

const Nav = React.lazy(() => import('../ui/organisms/Nav/Nav'));
const Footer = React.lazy(() => import('../ui/organisms/Footer'));
const SvgFilter = React.lazy(() => import('components/Svg/SvgFilter'));
const CharcuterieBgPicker = React.lazy(() => import('./CharcuterieBgPicker'));

const DelayLoaded = ({
  component: Component,
  loaded: l,
  transitionDelay,
  ...props
}) => {
  const loaded = useLoaded(l, transitionDelay);
  return <Component loaded={loaded} {...props} />;
};

export const transitionDelay = 600;
const MainRouter = () => {
  const ref = useRef();
  const location = useLocation();
  const [lastLocation, setLastLocation] = useState('');
  const [initial, setInitial] = useState(false);

  // console.log(location.pathname, lastLocation.pathname);
  const actualDelay = initial ? transitionDelay : 0;
  const transitions = useTransition(
    location.pathname !== lastLocation.pathname ? location : lastLocation,
    {
      config: {
        duration: 0,
      },
      from: { opacity: 0, zIndex: 1, position: 'absolute', top: 0, left: 0 },
      enter: {
        opacity: 1,
        zIndex: 2,
        delay: actualDelay,
        position: 'relative',
      },
      leave: {
        opacity: 0,
        zIndex: 1,
        delay: actualDelay,
      },
      onPause: () => {},
    },
  );

  useEffect(() => {
    if (lastLocation?.pathname === location.pathname) return;
    setLastLocation(location);
  }, [location, lastLocation]);

  useEffect(() => {
    const handleLoad = () => {
      setInitial(true);
    };
    window.addEventListener('load', handleLoad);

    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, [setInitial]);

  useEffect(() => {
    import('../scss/lazy.min.css');
  }, []);

  return (
    <>
      <LocomotiveScrollProvider
        containerRef={ref}
        transitionDelay={transitionDelay}
        options={{
          smooth: !IS_IE,
        }}
        watch={[]}
      >
        <Suspense fallback={null}>
          <CharcuterieBgPicker />
          <SvgFilter />
        </Suspense>
        <Suspense fallback={null}>
          <Nav />
          {/* <GewinnspielBanner /> */}
        </Suspense>
        <div ref={ref} className="main-content" data-scroll-container>
          {transitions((style, item, t, i) => {
            return (
              <a.div
                key={item.key}
                style={{
                  width: '100%',
                  ...style,
                }}
              >
                <Suspense fallback={<div />}>
                  <Switch location={item}>
                    {routes.map(({ component, path, ...props }) => (
                      <Route
                        {...props}
                        key={path}
                        path={path}
                        children={
                          <DelayLoaded
                            transitionDelay={actualDelay}
                            component={component}
                            loaded={
                              matchPath(item.pathname, { path, ...props }) &&
                              t.phase === 'enter'
                            }
                            path={item.pathname}
                          />
                        }
                      />
                    ))}
                  </Switch>
                  {/* SETUP ROUTES */}

                  <Footer loaded={t.phase === 'enter'} data-scroll-section />
                </Suspense>
              </a.div>
            );
          })}
        </div>
      </LocomotiveScrollProvider>
      <div id="lightbox"></div>
      <div id="modalbox"></div>
    </>
  );
};

export default MainRouter;
