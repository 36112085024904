import { createContext, useContext, useEffect, useRef, useState } from 'react';
import LocomotiveScroll from 'locomotive-scroll';
import 'scss/vendor/locomotive-scroll.css';
import { useLocation } from 'react-router';
import { IS_IE } from 'utils';
import useResizeObserver from 'use-resize-observer';

const LocomotiveContext = createContext();
export const useLocomotive = () => useContext(LocomotiveContext);

export const LocomotiveScrollProvider = ({
  options = {},
  containerRef,
  transitionDelay = 0,
  watch = [],
  ...props
}) => {
  const [isReady, setIsReady] = useState();
  const [extraOptions, setExtraOptions] = useState({});
  const LocomotiveScrollRef = useRef(null);
  const { pathname } = useLocation();
  const { curPathname, setCurPathname } = useState('');
  const { height } = useResizeObserver({ ref: containerRef });

  useEffect(() => {
    if (!containerRef?.current) return;
    LocomotiveScrollRef.current = new LocomotiveScroll({
      smooth: !IS_IE,
      // scrollFromAnywhere: true,
      ...options,
      ...extraOptions,

      el: containerRef?.current,
    });

    // console.log(extraOptions);
    // console.log(LocomotiveScrollRef.current);
    const readyTimeout = setTimeout(() => {
      setIsReady(true);
    }, 300);

    const updateTimeout = setTimeout(() => {
      LocomotiveScrollRef.current.update();
    }, transitionDelay + 200);

    return () => {
      clearTimeout(readyTimeout);
      clearTimeout(updateTimeout);
      LocomotiveScrollRef.current?.destroy();
      setIsReady(false);
    };
    // eslint-disable-next-line
  }, [containerRef, extraOptions]);
  useEffect(() => {
    if (pathname !== curPathname) return;
    setCurPathname(pathname);
  }, [pathname, curPathname, setCurPathname]);

  useEffect(() => {
    // let resizeTimeout;
    const handleLoad = () => {
      LocomotiveScrollRef.current?.update();
      setTimeout(() => {
        LocomotiveScrollRef.current?.update();
        // console.log(LocomotiveScrollRef.current?.scroll?.getScrollBar());
      }, 500);
    };
    window.addEventListener('load', handleLoad);

    const toggleLazy = (args) => {
      if (args.obj.el.src) return;
      let src = args.obj.el.dataset.src;
      if (src.length) {
        if (args.obj.el.tagName === 'IMG' || args.obj.el.tagName === 'VIDEO') {
          args.obj.el.src = src;
        } else {
          args.obj.el.style.backgroundImage = `url('${src}')`;
        }
        // setData('lazy', '', args.obj.el);
      }
    };
    const handleAutoplay = ({ way, obj }) => {
      if (!obj.el.src) return;
      // console.log('autoplay', obj.el.src);
      obj.el.setAttribute('autoplay', true);
      // if (way === 'enter') return obj.el.play();
      // if (way === 'exit') return obj.el.pause();
    };

    const handleCall = (func, way, obj) => {
      // Using modularJS
      const functions = typeof func === 'string' ? [func] : func;
      functions.forEach((f) => {
        if (f === 'toggleLazy') {
          toggleLazy({ way, obj });
        }
        if (f === 'autoplay') {
          handleAutoplay({ way, obj });
        }
      });
    };
    LocomotiveScrollRef.current.on('call', handleCall);

    return () => {
      window.removeEventListener('load', handleLoad);
      LocomotiveScrollRef.current.off('call', handleCall);
    };
  }, [LocomotiveScrollRef]);

  useEffect(() => {
    // handle scroll reset;
    LocomotiveScrollRef.current?.stop();
    let timeout = setTimeout(() => {
      LocomotiveScrollRef.current?.scrollTo(0, {
        duration: 0,
        disableLerp: true,
      });
      LocomotiveScrollRef.current?.start();
    }, transitionDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [pathname, transitionDelay]);

  useEffect(() => {
    LocomotiveScrollRef.current?.update();
    // eslint-disable-next-line
  }, [...watch, height, isReady, pathname]);

  return (
    <LocomotiveContext.Provider
      {...props}
      value={{
        scroll: LocomotiveScrollRef.current,
        isReady,
        options: extraOptions,
        setOptions: setExtraOptions,
        transitionDelay,
      }}
    >
      {props.children}
    </LocomotiveContext.Provider>
  );
};

LocomotiveScrollProvider.defaultProps = {
  watch: [],
};
