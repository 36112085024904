import React, { useState } from 'react';
import { useCookiePopup } from 'context';
import map from 'lodash/map';
import { Checkbox } from 'ui';
import { Button } from 'ui/atoms/Button/Button';

const data = {
  essential: {
    label: 'Essenziell',
    desc: 'Essenzielle Cookies ermöglichen grundlegende Funktionen und sind für die einwandfreie Funktion der Website erforderlich.',
  },
  statistics: {
    // googleAnalytics | facebookPixel
    label: 'Statistiken',
    desc: 'Statistik Cookies erfassen Informationen anonym. Diese Informationen helfen uns zu verstehen, wie unsere Besucher unsere Website nutzen.',
  },
  media: {
    // mapbox
    label: 'Externe Medien',
    desc: 'Inhalte von Videoplattformen und Social-Media-Plattformen werden standardmäßig blockiert. Wenn Cookies von externen Medien akzeptiert werden, bedarf der Zugriff auf diese Inhalte keiner manuellen Einwilligung mehr.',
  },
};

const acceptAll = (data) => {
  const results = {};

  Object.keys(data).forEach((key) => {
    results[key] = true;
  });
  return results;
};
const denyAll = (data) => {
  const results = {};

  Object.keys(data).forEach((key) => {
    results[key] = false;
  });
  return { ...results, essential: true };
};

export const CookiePopup = () => {
  const { setCookiePreferences, noticed, ...cookieState } = useCookiePopup();
  const [showOptions, setShopOptions] = useState(false);

  const handleOnChange = ({ target: { name, checked } }) => {
    setCookiePreferences({
      [name]: checked,
    });
  };

  // const handleMany = (bool) => () => {
  //   let newState = cookieState;
  //   Object.keys(newState).forEach((key) => {
  //     newState[key] = bool;
  //   });

  //   setCookiePreferences({ noticed: true, ...newState });
  // };

  // const selected = useMemo(
  //   () => Object.keys(cookieState).filter((key) => cookieState[key]),
  //   [cookieState],
  // );

  if (noticed) return null;
  return (
    <div
      className={`cookie-notice__container row justify-center flex-align-center p-2 pr-3 ${
        showOptions ? 'cookie-details' : ''
      }`}
    >
      <div className="cookie-notice rounded">
        <div className="align-center mb-3">
          <h3 className=" fw-bold">Wir verwenden Cookies</h3>
        </div>
        <div className="pb-2 gray-300  align-center">
          <p>
            Mit der Nutzung dieser Website erklären Sie sich damit
            einverstanden, dass wir Cookies verwenden. Weitere Informationen
            dazu finden Sie in unserem Datenschutz.
          </p>
        </div>

        {showOptions ? (
          <div
            className="pb-x3 row flex-align-stretch "
            style={{ margin: '0 -8px' }}
          >
            {map(cookieState, (value, key) => (
              <div className="col-12">
                <div key={key} className="checkbox-container">
                  <label className="row flex-align-center justify-space-between mb-1">
                    <div className="h6 fw-bold nom">{data[key].label}</div>
                    <Checkbox
                      checked={value}
                      onChange={(e) => {
                        if (key === 'essential') return;
                        handleOnChange(e);
                      }}
                      name={key}
                    />
                  </label>
                  <p>{data[key].desc}</p>
                </div>
              </div>
            ))}
          </div>
        ) : null}

        <div className="btn-container row justify-space-between flex-align-start pt-1">
          <div className="row flex-grow">
            <Button
              variant="outlined"
              className="btn mb-1 mb-md-0"
              size="small"
              onClick={() => setShopOptions(!showOptions)}
            >
              {showOptions ? 'Zurück' : 'Mehr Erfahren'}
            </Button>
            <Button
              variant="outlined"
              className="btn mb-1 mb-md-0"
              size="small"
              onClick={() =>
                setCookiePreferences({ noticed: true, ...denyAll(data) })
              }
            >
              Ablehnen
            </Button>
          </div>
          <Button
            variant="outlined"
            className="btn "
            size="small"
            onClick={() =>
              setCookiePreferences(
                showOptions
                  ? { noticed: true }
                  : { noticed: true, ...acceptAll(data) },
              )
            }
          >
            {showOptions ? 'mit Auswahl fortfahren' : 'Alle akzeptieren'}
          </Button>
        </div>
      </div>
    </div>
  );
};
