import React, { createContext, useContext, useEffect } from 'react';
import { useSpring } from 'react-spring';
// import { useSpring } from 'react-spring';
import { checkMobile, IS_IE } from 'utils';

const MouseMoveContext = createContext();
export const useNav = () => useContext(MouseMoveContext);
export const useMouseMove = () => useContext(MouseMoveContext);

const isMobile = checkMobile();

export const MouseMoveProvider = ({ children, config }) => {
  const [{ xy }, api] = useSpring(() => ({
    xy: [0, 0],
    config,
  }));

  useEffect(() => {
    const onMove = (e) => {
      const x = e.clientX || (e.touches && e.touches[0].clientX) || 0;
      const y = e.clientY || (e.touches && e.touches[0].clientY) || 0;
      api.start({
        xy: [x / window.innerWidth - 0.5, y / window.innerHeight - 0.5],
      });
    };
    function handleOrientationMove(e) {
      // https://stackoverflow.com/questions/40716461/how-to-get-the-angle-between-the-horizon-line-and-the-device-in-javascript
      const rad = Math.atan2(e.gamma, e.beta);
      if (Math.abs(rad) > 1.5) return;
      api.start({ xy: [-rad * 0.5, 0] });
      // TODO handle orientation.y
    }
    if (IS_IE) {
      return api.start({ xy: [0, 0] });
    }
    if (isMobile && window.innerWidth <= 998) {
      window.addEventListener('deviceorientation', handleOrientationMove);
    } else {
      window.addEventListener('mousemove', onMove);
    }
    return () => {
      if (isMobile && window.innerWidth <= 998) {
        window.removeEventListener('deviceorientation', handleOrientationMove);
      } else {
        window.removeEventListener('mousemove', onMove);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <MouseMoveContext.Provider value={xy}>{children}</MouseMoveContext.Provider>
  );
};
