import React from 'react';

// css in stuff >_>
export const DecoStroke = ({ className, ...props }) => {
  return (
    <>
      <hr {...props} className={`deco-stroke ${className}`} />
    </>
  );
};
