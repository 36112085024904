import React from 'react';
import PropTypes from 'prop-types';
import { Media } from '../Media/Media';

export const Section = ({
  className,
  bgImg,
  bgImgSrcSet,
  children,
  style,
  bgImgStyle,
  ...props
}) => {
  return (
    <section {...props} style={style} className={className}>
      {bgImg ? (
        <Media
          src={bgImg}
          className="overlay object-fit-cover"
          style={{
            zIndex: 0,
            maxWidth: 'none',
            objectPosition: 'center top',
            background: 'none',
            ...bgImgStyle,
          }}
          loading="lazy"
        />
      ) : null}
      {bgImgSrcSet ? (
        <Media
          src={bgImgSrcSet.src}
          srcSet={bgImgSrcSet.srcSet}
          sizes={bgImgSrcSet?.sizes || '100vw'}
          className="overlay object-fit-cover"
          style={{
            zIndex: 0,
            maxWidth: 'none',
            objectPosition: 'center top',
            background: 'none',
            ...bgImgStyle,
          }}
          loading="lazy"
        />
      ) : null}
      {children}
    </section>
  );
};

Section.propTypes = {
  className: PropTypes.string,
  bgImg: PropTypes.string,
};

Section.defaultProps = {
  className: '',
  bgImg: '',
};
