import React from 'react';

export const FancyBorder = ({ className, hideBottom, children, ...props }) => {
  return (
    <>
      {/* <div
        className="overlay"
        style={{
          backgroundColor: 'transparent',
          backgroundImage: 'url(/assets/bgs/rahmen.png)',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'none',
          pointerEvents: 'none',
        }}
      ></div> */}
      <div {...props} className={`fancy-border overlay ${className}`}>
        <img
          className="top-left"
          src="/assets/fancy-border/top-left.png"
          alt=""
        />
        <img
          className="top-right"
          src="/assets/fancy-border/top-right.png"
          alt=""
        />
        <img
          className="bottom-left"
          src="/assets/fancy-border/bottom-left.png"
          alt=""
        />
        <img
          className="bottom-right"
          src="/assets/fancy-border/bottom-right.png"
          alt=""
        />
        <img className="f-top" src="/assets/fancy-border/top.png" alt="" />
        <img className="f-left" src="/assets/fancy-border/left.png" alt="" />
        <img className="f-right" src="/assets/fancy-border/right.png" alt="" />
        {!hideBottom ? (
          <img
            className="f-bottom"
            src="/assets/fancy-border/bottom.png"
            alt=""
          />
        ) : null}
        {children}
      </div>
    </>
  );
};
