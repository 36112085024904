import axios from 'axios';
import React, { useState, createContext, useContext, useEffect } from 'react';

const UserLocationContext = createContext();
export const useUserLocation = () => useContext(UserLocationContext);

export const UserLocationContextProvider = ({ children }) => {
  const [state, setState] = useState({});

  useEffect(() => {
    const gLocation = window.localStorage.getItem('location');
    if (gLocation) {
      const parsedData = JSON.parse(gLocation);
      return setState(parsedData);
    }

    axios.get('/get-location/').then((res) => {
      window.localStorage.setItem('location', JSON.stringify(res.data));
      setState(res.data);
    });
  }, []);

  return (
    <>
      <UserLocationContext.Provider value={state}>
        {children}
      </UserLocationContext.Provider>
    </>
  );
};
