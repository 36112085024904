import React from 'react';
import { Media } from 'ui/atoms/Media/Media';
import { InViewFade } from 'ui';

const iToSpeed = [1, 1.5];

const SingleImage = ({ src, alt }) => {
  return (
    <div className="img-collage" style={{ perspective: '1000px' }}>
      <InViewFade fade="bend-bottom" style={{ zIndex: 10 }}>
        <img
          data-scroll
          data-scroll-speed="0.5"
          className="img-single"
          src={src}
          alt={alt}
        />
      </InViewFade>
      <InViewFade
        className="fw fh absolute"
        delay={400}
        style={{ top: 0, left: 0 }}
      >
        <div data-scroll data-scroll-speed="-1" className="deco-1"></div>
        <div data-scroll data-scroll-speed="1" className="deco-2"></div>
      </InViewFade>
    </div>
  );
};

export const ImgCollage = ({ images, ...props }) => {
  if (!images || images.length === 0) return <SingleImage {...props} />;
  return (
    <div {...props} className="img-collage" style={{ perspective: '1000px' }}>
      {/* <img src={src} alt={alt} /> */}
      {images.map((media, i) => (
        <InViewFade
          fade="bend-right-reverse"
          key={i}
          data-scroll
          data-scroll-offset="30%, 0%"
          data-scroll-speed={iToSpeed[i]}
          delay={i * 100}
        >
          <Media className={`img-${i}`} {...media} />
        </InViewFade>
      ))}
    </div>
  );
};

ImgCollage.propTypes = {};
ImgCollage.defaultProps = {
  images: [],
};
