import React, {
  createContext,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react';

import Snacks from 'theme/Snacks';

const OnkelHerbert = React.lazy(() => import('theme/OnkelHerbert'));
// const Snacks = React.lazy(() => import('theme/Snacks'));

export const ThemeContext = createContext();
export const useTheme = () => useContext(ThemeContext);

export const ThemeContextProvider = ({ children, ...props }) => {
  const [theme, setTheme] = useState(props.theme || 'dark');
  useEffect(() => {
    if (props.theme) {
      setTheme(props.theme);
    }
  }, [props.theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <Suspense fallback={null}>
        {theme === 'theme-onkel-herbert' ? <OnkelHerbert /> : null}
        {theme === 'theme-snacks' ? <Snacks /> : null}
      </Suspense>
      <div className={theme}>{children}</div>
    </ThemeContext.Provider>
  );
};
