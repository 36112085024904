import React from 'react';
import { AwesomeIcon } from 'ui/atoms/AwesomeIcon/AwesomeIcon';
import { SimpleLink } from 'ui/atoms/SimpleLink';

export const SocialMedia = ({ className, ...props }) => {
  return (
    <div {...props} className={`row flex-align-center ${className}`}>
      <SimpleLink
        aria-label="facebook"
        className="social-icon"
        target="_blank"
        href="https://www.facebook.com/malbunerschweiz"
        rel="noopener noreferrer"
      >
        <AwesomeIcon icon="facebook" prefix="fab" />
      </SimpleLink>
      <hr className="trenner-horizontal mr-1 ml-1" />
      <SimpleLink
        aria-label="instagram"
        className="social-icon"
        target="_blank"
        href="https://www.instagram.com/malbesser.malbuner/"
        rel="noopener noreferrer"
      >
        <AwesomeIcon icon="instagram" prefix="fab" />
      </SimpleLink>
      <hr className="trenner-horizontal mr-1 ml-1" />
      <SimpleLink
        aria-label="youtube"
        className="social-icon"
        target="_blank"
        href="https://youtube.malbuner.ch"
        rel="noopener noreferrer"
      >
        <AwesomeIcon icon="youtube" prefix="fab" />
      </SimpleLink>
      <hr className="trenner-horizontal mr-1 ml-1" />
      <SimpleLink
        aria-label="spotify"
        className="social-icon"
        target="_blank"
        href="https://open.spotify.com/user/31ltgnlknqm45lxjugb7orkx3gum"
        rel="noopener noreferrer"
      >
        <AwesomeIcon icon="spotify" prefix="fab" />
      </SimpleLink>
    </div>
  );
};
