import React from 'react';
import { CookieContextProvider, useCookiePopup } from './cookie-context';
import { ThemeContextProvider, useTheme } from './theme-context';
import { NavContextProvider, useNav } from './nav-context';
import { MouseMoveProvider, useMouseMove } from './mouse-move-context';
import { FooterContextProvider, useFooter } from './footer-context';
import {
  UserLocationContextProvider,
  useUserLocation,
} from './user-location-context';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Context = ({ children }) => {
  return (
    <ThemeContextProvider>
      <CookieContextProvider>
        <NavContextProvider>
          <FooterContextProvider>
            <UserLocationContextProvider>
              <MouseMoveProvider>
                <CaptchaContext>{children}</CaptchaContext>
              </MouseMoveProvider>
            </UserLocationContextProvider>
          </FooterContextProvider>
        </NavContextProvider>
      </CookieContextProvider>
    </ThemeContextProvider>
  );
};

const CaptchaContext = ({ children }) => {
  const { noticed, essential } = useCookiePopup();
  return noticed && essential ? (
    <GoogleReCaptchaProvider reCaptchaKey="6Le62gohAAAAAEUKaCJpvd8zFpmiTf_SrP1ysI15">
      {children}
    </GoogleReCaptchaProvider>
  ) : (
    children
  );
};

export default Context;
export * from './locomotive-scroll-context';
export {
  useTheme,
  useCookiePopup,
  useNav,
  useMouseMove,
  useFooter,
  useUserLocation,
};
