import React from 'react';
import PropTypes from 'prop-types';

const createClassName = (props) => {
  return props.join(' ');
};

export const Input = ({
  onChange,
  value,
  label,
  className,
  classNameContainer,
  children,
  variant,
  color,
  size,
  startIcon,
  endIcon,
  iconAction = () => {},
  ...props
}) => {
  const combinedClassName = createClassName([variant, color, size]);
  return (
    <div className={`input-container ${classNameContainer}`}>
      {startIcon ? (
        <span className="icon-left nom" onClick={iconAction}>
          {startIcon}
        </span>
      ) : null}
      <input
        className={`input ${combinedClassName} ${className}`}
        onChange={onChange}
        value={value}
        {...props}
      />
      {endIcon ? (
        <span className="icon-right nom" onClick={iconAction}>
          {endIcon}
        </span>
      ) : null}
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.oneOf([
    'contained',
    'outlined',
    'outlined secondary',
    'line',
  ]),
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'accent']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onClick: PropTypes.func,
  href: PropTypes.string,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  color: 'primary',
  size: 'medium',
  className: '',
};
