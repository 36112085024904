export const staticData = {
  companyName: 'Herbert Ospelt Anstalt',
  email: {
    href: 'mailto:info@ospelt.com',
    label: 'info@ospelt.com',
    target: '__blank',
  },
  tel: {
    href: 'tel:0041583771119',
    label: '+41 58 377 1119',
  },
  address: [
    'Schaanerstrasse 79',
    'FL 9487 Bendern',
    'Fürstentum Liechtenstein',
  ],
  website: {
    href: 'https://www.ospelt.com',
    label: 'www.ospelt.com',
    target: '_blank',
  },
  // facebookLink: 'https://www.facebook.com/Lorem Ipsum/',
};
