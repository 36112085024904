import { useIsMobile } from 'hooks';
import PropTypes from 'prop-types';
import React from 'react';
import { Media } from 'ui';
import { SimpleLink } from 'ui/atoms/SimpleLink';

export const PageSelection = ({
  data,
  className = '',
  sizes,
  children,
  ...props
}) => {
  const l = data.length;
  const isMobile = useIsMobile(998);
  const genClass =
    l === 6
      ? 'col-6 col-md-4 col-lg-2 p-0'
      : l === 3
      ? 'col-6 col-md-4 col-lg-4 p-0'
      : isMobile
      ? 'col-4 col-md-4 col-lg-2 p-0'
      : 'grow-with-it column flex-basis-0';

  return (
    <div
      {...props}
      className={`row page-selection p-0 ${className} columns-${l}`}
    >
      {data.map(({ src, srcSet, title, text, link, className = '' }, i) => (
        <div key={i} className={`${genClass} page-selection-col`}>
          <SimpleLink
            className="row flex-align-end p-2 page-selection-card fh"
            to={link}
          >
            <Media
              className="fw page-selection-image"
              srcSet={srcSet}
              src={src}
              sizes={
                i + 1 === data.length
                  ? '(max-width: 768px) 100vw,(max-width: 998px) 33vw, 20vw'
                  : sizes
              }
              loading="lazy"
            />
            <div className="page-selection-content">
              <h4
                className={'fw-black ' + className}
                style={{ wordBreak: 'keep-all' }}
              >
                {title}
              </h4>
              <p className="large fw-regular">{text}</p>
            </div>
            <div className="page-selection-overlay"></div>
          </SimpleLink>
        </div>
      ))}
      {children}
    </div>
  );
};

PageSelection.propTypes = {
  images: PropTypes.node,
};

PageSelection.defaultProps = {
  images: [],
};
