import React, { useState, createContext, useContext } from 'react';

const FooterContext = createContext();
export const useFooter = () => useContext(FooterContext);

export const FooterContextProvider = ({ children }) => {
  const [state, setState] = useState(true);

  return (
    <>
      <FooterContext.Provider value={{ footer: state, setFooter: setState }}>
        {children}
      </FooterContext.Provider>
    </>
  );
};
